/*
 * Created by Oleg Frolov
 * © 2017 | www.artof.pro
 *
 * Stylesheet for Comments
 *-------------------------------------------------------------------------*/

// Comments

  .post-comments {
    nav {
      font-weight: 500;

      a {
        &.active {
          color: $body-color;
        }

        + a {
          margin-left: 25px;
        }
      }
    }
  }

  .moderator-actions {
    margin-top: -15px;
  }

// Comment item

  .comment-item-wrapper {
    @include media-breakpoint-down(xs) {
      @include block();

      margin: 0 -15px;
      border-radius: 0;
      transition: 1.5s;

      &.highlighted {
        background-color: #f7f6d9;
      }

      &.answer {
        margin-left: 5px;

        &:before {
          content: "";
          position: relative;
          display: block;
          top: -20px;
          left: -40px;
          height: 20px;
          width: 20px;
          background: url(../../images/icon-circle.svg) no-repeat 50% 50% / 8px 8px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .comment-item {
        transition: 1.5s;
      }

      &.highlighted .comment-item {
        background-color: #f7f6d9;
      }

      &.answer {
        &:before {
          content: "";
          position: relative;
          display: block;
          top: 0;
          left: 0;
          height: 30px;
          width: 30px;
          background: url(../../images/icon-circle.svg) no-repeat 50% 50% / 10px 10px;
        }
      }
    }

    .comment-item {
      @include media-breakpoint-up(sm) {
        @include block();
      }

      //transition: 1.5s;
      //
      //&.highlighted {
      //  background-color: #f7f6d9;
      //}

      header {
        margin-bottom: 25px;

        @include media-breakpoint-down(xs) {
          margin-bottom: 15px;
        }

        .btn-link + .btn-link {
          margin-left: 10px;
        }

        .bans {
          display: inline-block;
          color: $red;
          font-size: 10px;
          font-weight: 500;
          line-height: 1;
          padding: 3px;
          background-color: rgba($red, .15);
          border-radius: 2px;
          margin-left: 5px;
        }
      }

      .std {
        font-size: 16px;
        word-break: break-word;
        text-align: justify;
        white-space: pre-wrap;

        @include media-breakpoint-down(xs) {
          font-size: 14px;
          text-align: left;
        }
      }

      .parent-user-link {
        display: inline;

        .user-avatar {
          display: inline;
        }

        .profile-modal {
          .user-avatar {
            display: flex;
          }
        }
      }

      .details {
        font-size: 12px;

        b {
          display: block;
          font-weight: 500;
        }
      }

      .edited {
        margin: 30px -30px 0;
        padding: 30px 30px 0;
        position: relative;
        border-top: $gray-200 1px solid;

        @include media-breakpoint-down(md) {
          margin: 20px -20px 0;
          padding: 20px 20px 0;
        }

        .btn {
          svg {
            vertical-align: middle;
          }

          &.opened {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .reply {
        margin: 30px -30px 0;
        padding: 30px 30px 0;
        position: relative;
        border-top: $gray-200 1px solid;

        @include media-breakpoint-down(md) {
          margin: 20px -20px 0;
          padding: 20px 20px 0;
        }

        textarea.form-control {
          padding: 0;
          border: transparent;
          background-color: transparent;
          resize: none;
          font-size: 16px;
          line-height: 1.5;
        }

        .content-editable {
          font-size: 16px;
          line-height: 1.5;
          box-shadow: none;
          outline: none;

          b {
            font-weight: 500;
            position: relative;

            &:after {
              content: ' ' attr(data-placeholder);
              display: inline-block;
              position: absolute;
              left: 100%;
              margin-left: 7px;
              color: $gray-600;
              font-weight: 400;
              white-space: nowrap;
              pointer-events: none;
            }
          }

          &.has-text {
            b:after {
              display: none;
            }
          }
        }
      }

      .ban-reason {
        border: 2px solid rgba($red, .15);
        padding: 20px;
        border-radius: $border-radius;
      }

      &.bad .std{
        background-color: #f8edeb;
        border-radius: 8px;
        padding: 20px;
      }

      &.hidden {
        header {
          .opacity {
            &.text-danger {
              color: rgba($red, .5) !important;
            }

            &.user-avatar,
            &.bans {
              opacity: 0.5;
            }

            &.text-primary,
            &.btn-link {
              color: rgba($brown, .5) !important;

              &.moderator-profile-link {
                color: white !important;
                background: #f1d4d0;
              }
            }

            &.text-muted {
              color: rgba($gray-600, .5) !important;

              .separator-dot,
              .text-muted {
                color: rgba($gray-600, .5) !important;
              }
            }
          }
        }

        .opacity.user-link {
          color: rgba($brown, .5) !important;
        }

        .std {
          span {
            opacity: 0.5;
          }
        }
      }

      &.form {
        border-color: theme-color('primary');

        textarea.form-control {
          padding: 0;
          border: transparent;
          background-color: transparent;
          resize: none;
          font-size: 16px;
          line-height: 1.5;
        }

        .image-holder {
          max-width: 200px;
          max-height: 100px;
          position: relative;

          .btn {
            border: none;
            border-radius: 100%;
            position: absolute;
            top: -6px;
            right: -6px;
            width: 20px;
            height: 20px;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
          }
        }

        .visibility {
          display: flex;
          border-radius: $border-radius;
          border: $gray-200 1px solid;

          label {
            flex: 1;
            position: relative;
            margin: 0;

            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;

              &:checked ~ span {
                background-color: theme-color('primary');
                color: $white;
              }
            }

            span {
              display: flex;
              width: 100%;
              height: 40px;
              align-items: center;
              justify-content: center;
              border-radius: $border-radius;
              font-weight: 400;
              color: $gray-600;
            }
          }
        }

        .settings {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -10px;

          .custom-checkbox {
            display: block;
            flex: 0 0 50%;
            margin: 0 0 20px;
            padding: 0 10px;
            min-height: 1.5rem;

            @include media-breakpoint-down(sm) {
              padding: 0 2.5rem 0 10px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            @include media-breakpoint-down(sm) {
              flex: 0 0 100%;
              width: 100%;
            }

            .custom-control-indicator {
              left: auto;
              right: 10px;
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }

      .comment-likes {
        width: 200px;

        @include media-breakpoint-down(xs) {
          width: 160px;
        }
      }
    }

    .comment-buttons {
      @include media-breakpoint-up(sm) {
        .btn-shadow {
          background-color: #fff;
          display: block;
          padding: 10px;
          box-shadow: 0 5px 10px 0 rgba($black, .05) !important;
        }
      }

      @include media-breakpoint-down(xs) {
        margin-top: 15px;

        .inner {
          display: flex;
          position: relative;
          margin: 0 -5px;
          flex-wrap: wrap;

          > div {
            flex: 1;
            padding: 0 5px;

            &.hide, &.restore {
              @include media-breakpoint-down(xs) {
                order: 1;
              }
            }

            .dropdown-toggle,
            .btn-shadow {
              display: block;
              width: 100%;
            }

            .btn-shadow {
              &.btn-comment-ban {
                background-color: rgba($red, .15);
                color: $red;

                &:hover, &:focus, &:active {
                  background-color: $red;
                  color: $white;
                }
              }

              &.btn-comment-show {
                background-color: rgba($green, .15);
                color: $green;

                &:hover, &:focus, &:active {
                  background-color: $green;
                  color: $white;
                }
              }

              &.btn-comment-hide,
              &.btn-comment-edit {
                background-color: rgba($brown, .15);
                color: $brown;

                &:hover, &:focus, &:active {
                  background-color: $brown;
                  color: $white;
                }
              }

              &.btn-comment-edit {
                flex: 0 0 100%;
                margin-bottom: 10px;

                + .btn {
                  margin-left: 0;
                }
              }
            }
          }
        }
      }
    }
  }

// Comment Add

  .comment-add {
    @include block();
    padding-top: 17px;
    padding-bottom: 17px;

    textarea.form-control {
      padding: 0;
      border: transparent;
      background-color: transparent;
      resize: none;
      font-size: 16px;
      line-height: 1.5;
    }
  }

// Comment Edit

  .comment-edit {
    @include block();
    border-color: theme-color('primary');

    header {
      margin-bottom: 25px;
    }

    textarea.form-control {
      padding: 0;
      border: transparent;
      background-color: transparent;
      resize: none;
      font-size: 16px;
      line-height: 1.5;
    }
  }
