/*
 * Created by Oleg Frolov
 * © 2017 | www.artof.pro
 *
 * Stylesheets extends bootstrap's buttons
 *-------------------------------------------------------------------------*/

button {
  background: transparent;
}

button, .btn {
  cursor: pointer;
  transition: all 200ms;
  outline: none !important;
  box-shadow: none !important;

  &[disabled] {
    cursor: not-allowed;
  }

  svg {
    vertical-align: baseline;
  }

  &.btn-primary {
    font-weight: 700;
  }

  &.btn-link {
    font-weight: 500;

    svg {
      fill: theme-color('primary')
    }

    .toggler {
      margin-left: 7px;
      transition: all 300ms;
      vertical-align: middle;
    }

    &.opened {
      .toggler {
        transform: rotate(-180deg);
      }
    }

    &:hover, &:focus, &:active {
      svg {
        fill: darken(theme-color('primary'), 15%);
      }
    }
  }

  &.btn-secondary {
    color: theme-color('primary');
  }

  &.danger-light {
    background-color: rgba($red, .15);
    color: $red;
    border-color: transparent;

    &:hover, &:focus, &:active {
      background-color: rgba($red, .3);
      color: $red;
    }
  }

  &.primary-light { // looks like .btn-secondary
    background-color: rgba($brown, .15);
    color: $brown;
    border-color: transparent;

    &:hover, &:focus, &:active {
      background-color: rgba($brown, .3);
      color: $brown;
    }
  }

  &.btn-apple {
    position: relative;
    background-color: $gray-300;
    color: $black;

    @include media-breakpoint-up(sm) {
      padding-left: 55px;

      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 40px;
        background: url(../../images/icon-ios.svg) no-repeat 50% 50% / 16px 16px;
        border-right: rgba($white, .2) 1px solid;
      }
    }

    @include media-breakpoint-down(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover, &:focus, &:active {
      background-color: darken($gray-300, 15%);
    }
  }

  &.btn-fb {
    position: relative;
    background-color: #3C5CA1;
    color: $white;

    @include media-breakpoint-up(sm) {
      padding-left: 55px;

      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 40px;
        background: url(../../images/icon-fb.svg) no-repeat 50% 50% / 16px 16px;
        border-right: rgba($white, .2) 1px solid;
      }
    }

    @include media-breakpoint-down(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover, &:focus, &:active {
      background-color: darken(#3C5CA1, 15%);
    }
  }

  &.btn-vk {
    position: relative;
    background-color: #507299;
    color: $white;

    @include media-breakpoint-up(sm) {
      padding-left: 55px;

      &:before {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 40px;
        background: url(../../images/icon-vk.svg) no-repeat 50% 50% / 16px 16px;
        border-right: rgba($white, .2) 1px solid;
      }
    }

    @include media-breakpoint-down(xs) {
      padding-left: 10px;
      padding-right: 10px;
    }

    &:hover, &:focus, &:active {
      background-color: darken(#507299, 15%);
    }
  }

  &.btn-vk-id,
  &.btn-vk-publish{
    position: relative;
    background-color: #4680C2;
    color: $white;
  }

  &.btn-vk-id {
    cursor: default;
  }

  &.btn-account {
    position: relative;
    color: $white;
    margin: -5px 0;
    cursor: auto;
    padding: 8px 15px 8px 42px;

    &.icon {
      padding-left: 0;
      width: 30px;
      height: 30px;
    }

    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      height: 100%;
      width: 30px;
      border-right: rgba($white, .2) 1px solid;
    }

    &.email {
      background-color: #739099;

      &:before {
        content: "@";
        padding-top: 8px;
      }

      &.icon {
        &:before {
          padding-top: 6px;
        }
      }
    }

    &.apple {
      background-color: $gray-300;

      &:before {
        background: url(../../images/icon-ios.svg) no-repeat 40% 50% / 16px 16px;
      }
    }

    &.fb {
      background-color: #3C5CA1;

      &:before {
        background: url(../../images/icon-fb.svg) no-repeat 40% 50% / 16px 16px;
      }
    }

    &.vk {
      cursor: pointer;
      background-color: #507299;

      &:before {
        background: url(../../images/icon-vk.svg) no-repeat 40% 50% / 16px 16px;
      }
    }
  }

  &.btn-dropdown {
    position: relative;

    .dropdown {
      left: 0;
      text-align: left;
      min-width: auto;
      max-height: none;
      padding: 0;

      li {
        padding: 10px 15px;

        + li {
          border-top: rgba($black, .05) 1px solid;
          margin: 0;
          }
      }
    }
  }

  &.btn-complain-ban {
    background-color: rgba($red, .15);
    color: $red;

    &:hover, &:focus, &:active {
      background-color: $red;
      color: $white;
    }
  }

  &.btn-sm {
    &.rounded {
      border-radius: 20px !important;
    }
  }

  &.btn-with-publisher {
    color: theme-color('success');
    background-color: #CAECDF;
    border-color: #CAECDF;

    &:hover {
      background-color: darken(#CAECDF, 15%);
      border-color: darken(#CAECDF, 20%);
    }
  }
}

.btn-wide {
  @include block();
  box-shadow: 0 10px 20px 0 rgba($black, .05) !important;
  height: 60px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  color: $brown;
  font-weight: 500;

  @include media-breakpoint-down(xs) {
    height: auto;
    padding: 10px 20px;
  }

  svg {
    margin-left: 10px;
    fill: $brown;
  }

  &.opened {
    .toggler {
      transform: rotate(-180deg);
    }
  }

  &:hover, &:focus, &:active {
    color: $body-color;

    svg {
      fill: $body-color;
    }
  }
}

.action-buttons {
  svg {
    margin-top: 3px;
  }
}

.btn-reset {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}

.dropdown-toggle {
  display: inline-block;
  position: relative;

  .dropdown {
    left: 0;
    text-align: left;
    padding: 0;

    li {
      padding: 10px 15px;

      button, a {
        display: block;
        min-width: 100%;
        text-align: left;
      }

      + li {
        border-top: rgba($black, .05) 1px solid;
        margin: 0;
      }
    }
  }
}
