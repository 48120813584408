/*
 * Created by Oleg Frolov
 * © 2017 | www.artof.pro
 *
 * Basic stylesheet for links, text, etc.
 *-------------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic');


// General styles

  html {
    width: 100vw; // fix for jumping scrollbar in windows desktops
    -webkit-font-smoothing: antialiased;
  }

  body {
    min-height: 100vh;
    padding-right: 0 !important; // fix for windows when padding-right appears at body on modal opening
  }

  html, body {
    overflow-x: hidden;
  }

  button, input, optgroup, select, textarea {
    font-family: $font-family-base;
  }

  a {
    cursor: pointer;
    transition: all 200ms;
  }

  svg {
    fill: $body-color;

    &.fill-primary { fill: theme-color('primary') !important; }
    &.fill-gray { fill: $gray-600 !important; }
    &.fill-white { fill: $white !important; }
    &.fill-green { fill: $green !important; }
    &.fill-red { fill: $red !important; }
  }

// Text extension classes

  .font-weight-500 {
    font-weight: 500;
  }

  .bad-words {
    padding: 2px;
    color: white;
    background: #bf4f43;
    border-radius: 3px;
  }

  .searched-text {
    background: #caebe0;
  }

  .lh-0 {
    line-height: 0;
  }

  .lh-15 {
    line-height: 1.5;
  }

// Root container

  @include media-breakpoint-down(md) {
    #app {
      position: relative;
      min-height: 100vh;
    }

    .container {
     padding: 0 15px;
   }
  }

// Position extension

  .p-static {
    position: static;
  }

  .p-relative {
    position: relative;
  }

// Datepicker styles rewrite

  .react-datepicker-wrapper {
    display: block;

    .react-datepicker__input-container {
      display: inline-block;
      width: auto;
    }
  }

  .react-datepicker-popper {
    margin: 0 !important;
    z-index: 9999;

    @include media-breakpoint-down(xs) {
      .react-datepicker__day-name,
      .react-datepicker__day,
      .react-datepicker__time-name {
        width: 1.5rem;
        line-height: 1.5rem;
      }

      .react-datepicker__time-container {
        width: 75px;

        .react-datepicker__time {
          .react-datepicker__time-box {
            width: 75px;
          }
        }
      }
    }

    .react-datepicker {
      border-radius: $border-radius;
      border: none;
      box-shadow: 0 7px 14px 0 rgba($black,.1);
      font-family: $font-family-base;
      font-size: $font-size-base;
      color: $body-color;

      .react-datepicker__navigation {
        top: 20px;
        border: none;
        height: 20px;
        width: 7px;
        background: url(../../images/icon-next.svg) no-repeat 50% 50% / 7px 11px;

        &.react-datepicker__navigation--previous {
          background-image: url(../../images/icon-prev.svg);
          left: 15px;
        }

        &.react-datepicker__navigation--next {
          right: 15px;

          &.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: 100px;
          }
        }


        span {
          display: none;
        }
      }

      .react-datepicker__day,
      .react-datepicker__day-name,
      .react-datepicker__time-name {
        margin: .15rem .45rem;
        font-weight: 500;
        color: $body-color;
        text-transform: capitalize;
        font-size: 14px;
      }

      .react-datepicker__day--keyboard-selected {
        background-color: $brown;
        color: $white !important;
      }

      .react-datepicker__triangle {
        display: none;
      }

      .react-datepicker__header {
        background: none;
        padding-top: 20px;
        border-bottom: none;

        .react-datepicker__current-month {
          font-weight: 500;
          font-size: 14px;
          color: $brown;
          text-transform: capitalize;
          margin-bottom: 10px;
          line-height: 20px;
        }
      }

      .react-datepicker__day {
        color: $brown;
      }

      .react-datepicker__day--outside-month {
        color: $gray-600;
      }

      .react-datepicker__day--disabled {
        color: $gray-200;
      }

      .react-datepicker__day--selected {
        color: $white;
        background-color: $body-color;
        border-radius: $border-radius;
      }

      .react-datepicker__time-container {
        border-left: $gray-200 1px solid;

        .react-datepicker__header--time {
          background: url(../../images/icon-time.svg) no-repeat 50% 50% / 1.5rem 1.5rem;

          .react-datepicker-time__header {
            opacity: 0;
          }
        }

        ul.react-datepicker__time-list {
          margin: 0;
          padding: 0 !important;

          li.react-datepicker__time-list-item--selected {
            color: $white;
            background-color: $body-color !important;
          }
        }
      }
    }
  }

// Emoji

  .emoji-icon {
    display: inline;
    margin: 0 5px;
    padding-left: 20px;
    color: theme-color("primary");

    &.angry {
      background: url('../../images/emoji/angry.png') no-repeat 0 50% / 16px 16px;
    }
    &.facepalm {
      background: url('../../images/emoji/facepalm.png') no-repeat 0 50% / 16px 16px;
    }
    &.joke {
      background: url('../../images/emoji/joke.png') no-repeat 0 50% / 16px 16px;
    }
    &.like {
      background: url('../../images/emoji/like.png') no-repeat 0 50% / 16px 16px;
    }
    &.love {
      background: url('../../images/emoji/love.png') no-repeat 0 50% / 16px 16px;
    }
    &.sad {
      background: url('../../images/emoji/sad.png') no-repeat 0 50% / 16px 16px;
    }
    &.ugh {
      background: url('../../images/emoji/ugh.png') no-repeat 0 50% / 16px 16px;
    }
    &.wow {
      background: url('../../images/emoji/wow.png') no-repeat 0 50% / 16px 16px;
    }
  }

.visibility_sensor {
  min-height: 1px;
}
