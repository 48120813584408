/*
 * Created by Oleg Asriyants
 *
 * Stylesheet for Statistics
 *-------------------------------------------------------------------------*/

  .statistics-container {
    width: 100%;

    > div {
      @include block();
    }

    .status-panel {
      padding: 15px 27px;

      @include media-breakpoint-down(md) {
        padding: 17px;
      }

      > div {
        border: 1px solid #ddd;
        text-align: center;
        padding: 3px 15px;

        .scheduled small {
          font-size: 1rem;
        }
      }
    }

    .active {
      color: $body-color;
    }

    .date-periods button,
    .date-groups button {
      padding-left: 7px;
      padding-right: 0;
      &:first-child {
        padding-left: 0;
      }
    }

    .graph-dots circle {
      fill: white;
      fill-opacity: 0;

      &.show-by-default {
        fill-opacity: 1;
        stroke: currentColor;
        stroke-width: "0.3%";
      }

      &.show {
        fill: currentColor;
        fill-opacity: 1;
      }
    }

    .graph-notes {
      display: none;
      border-radius: 3px;
      color: white;
      padding: 1px 2px;

      &.show {
        display: inline;
      }
    }

    .pie-chart-stat {
      float: left;
      width: 12px;
      height: 12px;
      border-radius: 2px;
      margin: 4px 8px 0 0;
    }

    .date-filters {
      .hide {
        display: none;
      }

      h6 {
        height: 17px;
      }

      .current-range {
        > div {
          display: inline-block;
          width: auto;
        }

        .delimiter {
          margin: 0 4px;
        }

        .react-datepicker-wrapper button {
          padding: 0;
        }
      }
    }

    .dot-tooltip {
      visibility: hidden;
      position: absolute;
      transform: translateX(-50%);
      width: auto;
      height: 20px;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 0 3px;
      line-height: 18px;
      vertical-align: middle;
      color: white;
      background-color: blue;
      margin: 0 auto;
      white-space: nowrap;

      &.show {
        visibility: visible;
      }

      .name {
        display: none;

        &.show {
          display: inline;
        }
      }
    }

    span.no-selection {
      -webkit-user-select: none; /* webkit (safari, chrome) browsers */
      -moz-user-select: none; /* mozilla browsers */
      -ms-user-select: none; /* IE10+ */
    }
  }
