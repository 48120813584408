/*
 * Created by Oleg Frolov
 * © 2017 | www.artof.pro
 *
 * Stylesheet for Users section
 *-------------------------------------------------------------------------*/

// List of Users

  .users {
    .data {
      @include block();

      .table {
        thead {
          th {
            border-top: none;
            border-bottom-width: 1px;
            color: $gray-600;
            font-weight: 500;
            text-align: center;
          }
        }
        td {
          text-align: center;
        }
      }

      .list {
        .item {
          padding: 0 20px 25px;
          margin: 0 -20px 25px;
          border-bottom: $gray-200 1px solid;

          &:last-child {
            border-bottom: none;
            padding-bottom: 5px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

// Profile

  .profile {
    @include block();
    background-color: $white;

    @include media-breakpoint-down(sm) {
      h1 {
        font-size: 1.5rem;
      }
    }

    @include media-breakpoint-down(xs) {
      h1 {
        font-size: 1.25rem;
      }
    }

    .user-avatar {
      .badge {
        width: 36px;
        height: 36px;
        border-width: 2.5px;
        left: 3px;
        top: 3px;

        svg {
          fill: $white;
          max-width: 70%;
          max-height: 70%;
        }

        @include media-breakpoint-down(xs) {
          width: 26px;
          height: 26px;
          left: -2px;
          top: -2px;
          border-width: 2px;

          svg {
            max-width: 12px;
            max-height: 12px;
          }
        }
      }

      &.keeper {
        img {
          border-width: 5px;
        }
      }

      @include media-breakpoint-down(xs) {
        img {
          max-width: 60px;
          max-height: 60px;
        }

        &.keeper {
          img {
            border-width: 3px;
          }
        }
      }
    }

    .reset-city-btn {
      position: absolute;
      top: 1px;
      right: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

// Profile info displayed in modal

  .profile-modal {
    font-size: 14px;
    color: $black;

    .user-avatar {
      .badge {
        width: 26px;
        height: 26px;
      }

      &.keeper {
        img {
          border: $green 4px solid;
        }
      }
    }

    a.user-name {
      color: $body-color;
    }
  }

// Common avatar classes

  .user-avatar {
    position: relative;
    display: flex;

    .badge {
      background-color: $orange;
      width: 20px;
      height: 20px;
      border: 1.5px solid $white;
      left: -4px;
      top: -4px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      border-radius: 100%;

      svg {
        fill: $white;
        max-width: 70%;
        max-height: 70%;
      }
    }
    img {
      object-fit: cover;
    }

    &.keeper {
      > img {
        border-radius: 100%;
        border: $green 3px solid;
      }
    }
  }

// Profile link

  .keeper-profile-link,
  .moderator-profile-link,
  .post-author {
    padding: 2px !important;
    border-radius: 3px;
  }

  .keeper-profile-link,
  .keeper-profile-link:hover {
    background-color: #83fda8;
  }

  .moderator-profile-link,
  .moderator-profile-link:hover {
    color: white;
    background-color: #bf4f43;
  }

  .post-author,
  .post-author:hover {
    background-color: #feebef;

    &.keeper-profile-link,
    &.keeper-profile-link:hover {
      background-color: #feebef;
    }
  }

  .active-author,
  .active-author:hover {
    font-weight: bold;
    color: $white;
    background-color: $red;
    padding: 3px;
    border-radius: 4px;
  }

  .removed-user,
  .removed-user:hover,{
    color: #595959;
  }

  .no-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

