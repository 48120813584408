/*
 * Created by Oleg Frolov
 * © 2017 | www.artof.pro
 *
 * Styles for Additional Side - Right Column
 *-------------------------------------------------------------------------*/

// Common styles

  aside {
    @include media-breakpoint-up(xl) {
      position: relative;
    }

    .aside-wrapper {
      // @include media-breakpoint-up(xl) {
      //   position: sticky;
      //   top: 100px;
      // }

      @include media-breakpoint-down(md) {
        position: fixed;
        overflow: auto;
        z-index: 9999;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $body-bg;
        padding: 25px 20px;

        &.static {
          position: relative;
          top: auto;
          left: auto;
          right: auto;
          bottom: auto;
          padding: 0;
        }
      }

      .aside-mobile-close {
        z-index: 9999;
        position: absolute;
        top: 28px;
        right: 20px;
        width: 18px;
        height: 18px;
      }
    }

    h3 {
      margin-bottom: 25px;
    }
  }

// Aside - Sorting

  .block-sorting {
    .dropdown {
      left: auto;
      right: 0;
    }

    .sort {
      margin-bottom: 25px;

      ul {
        display: flex;
        flex-wrap: wrap;
        border-radius: $border-radius;
        border: $gray-200 1px solid;

        li {
          flex: 0 0 50%;
          width: 50%;

          .btn-clear {
            background-color: transparent;
            border-color: transparent;
            color: $gray-600;

            &:hover, &:focus {
              color: $body-color;
            }
          }
        }
      }
    }
  }
